<template>
    <vs-tabs class="custom-tabs">
        <vs-tab label="Google Ads">
            <advertising-adwords-advertisers class="mt-5 mb-10"/>
            <advertising-adwords-competition-keywords/>
        </vs-tab>
        <vs-tab label="Google Shopping">
            <advertising-shopping-advertisers class="mt-5 mb-10"/>
            <advertising-shopping-competition-keywords/>
        </vs-tab>
    </vs-tabs>
</template>

<script>
import AdvertisingAdwordsAdvertisers from "@/components/yooda-components/app/advertising/AdvertisingAdwordsAdvertisers";
import AdvertisingAdwordsCompetitionKeywords from "@/components/yooda-components/app/advertising/AdvertisingAdwordsCompetitionKeywords";
import AdvertisingShoppingAdvertisers from "@/components/yooda-components/app/advertising/AdvertisingShoppingAdvertisers";
import AdvertisingShoppingCompetitionKeywords from "@/components/yooda-components/app/advertising/AdvertisingShoppingCompetitionKeywords";
export default {
    name: "AdvertisingAdwordsShoppingContainer",
    components: {
        AdvertisingAdwordsAdvertisers,
        AdvertisingAdwordsCompetitionKeywords,
        AdvertisingShoppingAdvertisers,
        AdvertisingShoppingCompetitionKeywords
    }
}
</script>

<style lang="scss">
.custom-tabs{
    .con-ul-tabs{
        .vs-tabs--li{
            background-color: #e8e8e8;
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
            &.activeChild{
                background-color: #fff;
                border: 1px solid #e0e0e0;
            }
        }
    }

    .vs-tabs--content{
        background-color: #fff;
        border: 1px solid #e0e0e0;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        border-bottom-left-radius: 8px;
    }

    .line-vs-tabs{
        margin-top: -2px;
    }

    .vs-tabs--li{
        span{
            padding: 0 22px;
        }
    }
}
</style>
