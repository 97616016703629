<template>
    <page-placeholder v-if="project.isLoaded">
        <v-row>
            <v-col :cols="12">
                <advertising-incoming-traffic/>
            </v-col>


            <v-col :xl="3" :lg="6" :md="6" :cols="12">
                <advertising-conversion/>
            </v-col>
            <v-col :xl="3" :lg="6" :md="6" :cols="12">
                <advertising-turnover/>
            </v-col>
            <v-col :xl="3" :lg="6" :md="6" :cols="12">
                <advertising-adwords-top-competitor/>
            </v-col>
            <v-col :xl="3" :lg="6" :md="6" :cols="12">
                <advertising-shopping-top-competitor/>
            </v-col>


            <v-col :xl="6" :lg="6" :md="6"  :cols="12">
                <advertising-adwords-performance/>
            </v-col>
            <v-col :xl="6" :lg="6" :md="6"  :cols="12">
                <advertising-shopping-performance/>
            </v-col>

            <v-col :cols="12">
                <advertising-adwords-shopping-container />
            </v-col>
        </v-row>
    </page-placeholder>

</template>


<script>

import PagePlaceholder from "@/components/yooda-components/PagePlaceholder";

import AdvertisingIncomingTraffic from "@/components/yooda-components/app/advertising/AdvertisingIncomingTraffic";
import AdvertisingConversion from "@/components/yooda-components/app/advertising/AdvertisingConversion";
import AdvertisingTurnover from "@/components/yooda-components/app/advertising/AdvertisingTurnover";
import AdvertisingAdwordsTopCompetitor from "@/components/yooda-components/app/advertising/AdvertisingAdwordsTopCompetitor";
import AdvertisingShoppingTopCompetitor from "@/components/yooda-components/app/advertising/AdvertisingShoppingTopCompetitor";
import AdvertisingAdwordsPerformance from "@/components/yooda-components/app/advertising/AdvertisingAdwordsPerformance";
import AdvertisingShoppingPerformance from "@/components/yooda-components/app/advertising/AdvertisingShoppingPerformance";
import AdvertisingAdwordsShoppingContainer from "@/components/yooda-components/app/advertising/AdvertisingAdwordsShoppingContainer";
export default {
    components: {
        PagePlaceholder,
        AdvertisingIncomingTraffic,
        AdvertisingConversion,
        AdvertisingTurnover,
        AdvertisingAdwordsTopCompetitor,
        AdvertisingShoppingTopCompetitor,
        AdvertisingAdwordsPerformance,
        AdvertisingShoppingPerformance,
        AdvertisingAdwordsShoppingContainer
    }
}

</script>


